import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";


import CompActive from '../Componentes/CompActive'
import { data_live } from '../DataAPI/datalive'
import { validarME } from '../DataAPI/apiMe'
import { MeuContexto } from "../App"

import { global } from '../DataAPI/global'

import VideoCarousel from './VideoCarousel';
import VideoCategoryList from './VideoCategoryList';
import './VideoApp.css';




let listacompleta = []
let pagenumber = 1
let vrfilter = ''
let vrmeusartistas = false
let novofilter = ''

const sampleVideos = [
  { thumbnail: 'userm.jpg', title: 'Video 1' },
  { thumbnail: 'userm.jpg', title: 'Video 2' },
  { thumbnail: 'userm.jpg', title: 'Video 3' },
  // Adicione mais vídeos aqui
];

const sampleCategories = [
  {
    name: 'Category 1',
    videos: [
      { thumbnail: 'userm.jpg', title: 'Video 1' },
      { thumbnail: 'userm.jpg', title: 'Video 2' },
      { thumbnail: 'userm.jpg', title: 'Video 3' },
      { thumbnail: 'userm.jpg', title: 'Video 4' },
      { thumbnail: 'userm.jpg', title: 'Video 5' },
      { thumbnail: 'userm.jpg', title: 'Video 6' },
      { thumbnail: 'userm.jpg', title: 'Video 7' },
      { thumbnail: 'userm.jpg', title: 'Video 8' },
      // Adicione mais vídeos aqui
    ],
  },
  {
    name: 'Category 2',
    videos: [
      { thumbnail: 'userm.jpg', title: 'Video 9' },
      { thumbnail: 'userm.jpg', title: 'Video 10' },
      { thumbnail: 'userm.jpg', title: 'Video 11' },
      { thumbnail: 'userm.jpg', title: 'Video 12' },
      { thumbnail: 'userm.jpg', title: 'Video 13' },
      { thumbnail: 'userm.jpg', title: 'Video 14' },
      { thumbnail: 'userm.jpg', title: 'Video 15' },
      { thumbnail: 'userm.jpg', title: 'Video 16' },
      { thumbnail: 'userm.jpg', title: 'Video 17' },
      { thumbnail: 'userm.jpg', title: 'Video 18' },
      // Adicione mais vídeos aqui
    ],
  },
  // Adicione mais categorias aqui
];

const VideoApp = () => {


  const { setlogado, setusername ,setuser} = useContext(MeuContexto)

  const [isloading, setisloading] = useState(true);

  const [liveson, setliveson] = useState([])
  const [filter, setFilter] = useState('');

  useEffect(
    () => {
      _carregatela()
    }, [])

  async function _carregatela() {


    const me = await validarME.me()
    if (me) {
      setuser(me)
      setusername(me.username)

      setisloading(false)

      setlogado(true)


    } else {
      setuser([])
      setisloading(false)
      setlogado(false)

    }

    Atualizar(1)

  }
  function _filtrar(e) {
    e.preventDefault()
    Atualizar(1)
  }
  async function Atualizar(page) {

    setisloading(true)

    pagenumber = page

    if (page === 1) {

      listacompleta = []

    }



    const rlista1 = await data_live.Listar(global.brl.REACT_APP_LISTAID, page, 'meusartistas');
    console.log(rlista1, 'rlista1')
    if (rlista1) {

      console.log(rlista1)
      listacompleta = [...listacompleta, ...rlista1]

      if (rlista1.length < 10) {
        await Atualizar1(page)
      }
      else {
        Atualizar1(1)

      }

    }
    setliveson(listacompleta)



    setisloading(false)


  }

  async function Atualizar1(page) {

    const rlista2 = await data_live.Listar1(global.userid, page, novofilter);
    console.log(rlista2, 'rlista2')
    if (rlista2) {
      listacompleta = [...listacompleta, ...rlista2]


      if (rlista2.length < 10) {
        await Atualizar2(page)
      }
      else {
        Atualizar2(1)

      }

    }


  }
  async function Atualizar2(page) {

    const rlista3 = await data_live.Listar2(global.userid, page, novofilter);
    console.log(rlista3, 'rlista3')

    if (rlista3) {
      listacompleta = [...listacompleta, ...rlista3]

    }


  }


  function _proximapg(e) {
    e.preventDefault()
    pagenumber++
    if (!isloading) { Atualizar(pagenumber) }

  }



  return ((isloading) ? (<>
    <div className="lbt4">
      <CompActive />
    </div>
  </>) : (<div>
    <VideoCarousel videos={sampleVideos} />
    <VideoCategoryList categories={sampleCategories} />
  </div>)
  );
};

export default VideoApp;
