import React, { useState } from 'react';
import PaymentForm from './PgTeste1';

const PaymentPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-6">Pagamento</h1>
      <button
        onClick={() => setIsModalOpen(true)}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg font-bold hover:bg-blue-600"
      >
        Abrir Formulário de Pagamento
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
          {/* Modal Container */}
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-6 my-16 p-6 relative overflow-y-auto max-h-[90vh]">
            {/* Botão Fechar */}
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            >
              ✖
            </button>
            {/* Formulário de Pagamento */}
            <PaymentForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
