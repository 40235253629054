import React, { useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './Rotas.css'



import Header from './Componets/Header'

import VideoApp from './Paginas/VideoApp';

import PgHome from './Paginas/PgHome'
import PgLives from './Paginas/PgLives'
import PgSignIn from './Paginas/PgSignIn'
import PgSignUp from './Paginas/PgSignUp'
import PgUsuario from './Paginas/PgUsuario'
import PgCanal from './Paginas/PgCanal'


import PgaSobre from './PaginasAux/PgaSobre'
import PgaSuporte from './PaginasAux/PgaSuporte'
import PgaTermos from './PaginasAux/PgaTermos'
import PgaPoliticas from './PaginasAux/PgaPoliticas'


import PgaAssinatura from './PaginasAux/PgaAssinatura'

import PgaIngressoTV from './PaginasAux/PgaIngressoTV'

import PgaBilheteria from './PaginasAux/PgaBilheteria'

import PgaIngresso from './Paginas/PgIngresso'

import { global } from './DataAPI/global'

import { MeuContexto } from "./App"



import NavCabecalho from './Navegacao/NavCabecalho'

import NavRodape from './Navegacao/NavRodape'
import PgAgenda from './Paginas/PgAgenda';


import PgTeste2 from './Paginas/PgTeste2'

function Rotas() {

  const { logado, setlogado, gett, setgett } = useContext(MeuContexto)


  useEffect(
    () => {




      if (!gett) {

        try {

          global.token = localStorage.getItem('@smizetv:token')

        } catch (error) {
          global.token = null

        }

        if (global.token === null) {
          setlogado(false)

        } else {

          setlogado(true)

        }

        ///global.token, ' pega token')

        setgett(true)

      } else {


        if (logado) {
          ///console.log(global.token, '-------- grava token')

          try {
            localStorage.setItem('@smizetv:token', global.token);
          } catch (error) {

          }
        }
        if (!logado) {

          ///   console.log(global.token, '-------- limpa token')
          global.token = null
          try {
            localStorage.removeItem('@smizetv:token');

          } catch (error) {

          }

        }

      }

    },
    [logado, setlogado, gett, setgett]
  )





  return (

    <Router>
      <div>
        <Header></Header>
        {/*   <NavCabecalho


        <div className="conteudo">

          <div className="conteudo2">
 />*/}
        <Switch>
          <Route exact path="/">
            <PgHome />
          </Route>
          <Route exact path="/Lives/:Procura">
            <PgLives />
          </Route>
          <Route path="/SignIn">
            <PgSignIn />
          </Route>
          <Route path="/SignUp">
            <PgSignUp />
          </Route>
          <Route path="/Usuario">
            <PgUsuario />
          </Route>
          <Route path="/VideoApp">
            <VideoApp />
          </Route>



          <Route path="/Canal/:liveId">
            <PgCanal />
          </Route>

          <Route path="/Agenda">
            <PgAgenda />
          </Route>

          <Route path="/Sobre">
            <PgaSobre />
          </Route>
          <Route path="/Suporte">
            <PgaSuporte />
          </Route>
          <Route path="/Termos">
            <PgaTermos />
          </Route>
          <Route path="/Politicas">
            <PgaPoliticas />
          </Route>
          <Route path="/Assinatura">
            <PgaAssinatura />
          </Route>
          <Route path="/Teste">
            <PgTeste2 />
          </Route>          
          <Route path="/Ingresso">
            <PgaIngresso />
          </Route>
          <Route path="/Bilheteria">
            <PgaBilheteria />
          </Route>          
          <Route path="/IngressoTV/:liveId">
            <PgaIngressoTV />
          </Route>

        </Switch>
        {/*
          </div>

        </div> */}


        <NavRodape />
      </div>



    </Router>
  );
}


export default Rotas;

