import React, { useState } from 'react';

import imgGold from '../img/gold.png'

import imgSilver from '../img/silver.png'
import imgBronze from '../img/bronze.png'


const TicketBoxOffice = () => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isHalfEntry, setIsHalfEntry] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);

  const tickets = [
    { id: 'bronze', name: 'Bronze', price: 30, image: imgBronze },
    { id: 'silver', name: 'Silver', price: 50, image: imgSilver },
    { id: 'gold', name: 'Gold', price: 80, image: imgGold },
  ];

  const handleTicketSelection = (ticket) => {
    setSelectedTicket(ticket);
    calculateTotalPrice(ticket.price, quantity, isHalfEntry);
  };

  const handleHalfEntryToggle = () => {
    const newIsHalfEntry = !isHalfEntry;
    setIsHalfEntry(newIsHalfEntry);

    if (selectedTicket) {
      calculateTotalPrice(selectedTicket.price, quantity, newIsHalfEntry);
    }
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10) || 1;
    setQuantity(newQuantity);

    if (selectedTicket) {
      calculateTotalPrice(selectedTicket.price, newQuantity, isHalfEntry);
    }
  };

  const calculateTotalPrice = (price, quantity, isHalf) => {
    setTotalPrice((isHalf ? price / 2 : price) * quantity);
  };

  const handlePurchase = () => {
    if (!selectedTicket) {
      alert('Por favor, selecione um tipo de bilhete.');
      return;
    }
    alert(
      `Compra concluída! \nTipo de Bilhete: ${selectedTicket.name}\nQuantidade: ${quantity}\nPreço Total: R$ ${totalPrice.toFixed(
        2
      )}`
    );
  };

  return (

<div className="flex justify-center mt-4 ">
      <div className="bg-white p-2 rounded-lg shadow-lg w-full max-w-2xl">

    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-2 text-black">
      <h1 className="text-3xl font-bold mb-6 text-black">Bilheteria</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6 text-black">
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className={`border rounded-lg p-4 text-center cursor-pointer ${
              selectedTicket?.id === ticket.id
                ? 'bg-blue-500 text-white'
                : 'bg-white text-black hover:bg-gray-200'
            }`}
            onClick={() => handleTicketSelection(ticket)}
          >
            <img
              src={ticket.image}
              alt={ticket.name}
              className="w-full h-42 object-cover rounded mb-4"
            />
            <h2 className="text-xl font-bold text-black">{ticket.name}</h2>
            <p className="text-lg text-black">R$ {ticket.price.toFixed(2)}</p>
          </div>
        ))}
      </div>

      <div className="mb-6">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={isHalfEntry}
            onChange={handleHalfEntryToggle}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-black text-lg">Meia Entrada</span>
        </label>
      </div>

      <div className="mb-6">
        <label className="block text-black font-bold mb-2">Quantidade de Ingressos</label>
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          min="1"
          className="w-20 px-3 py-2 border rounded-lg text-center text-black"
        />
      </div>

      <div className="mb-6">
        <p className="text-xl text-black">
          Preço Total: <span className="font-bold text-black">R$ {totalPrice.toFixed(2)}</span>
        </p>
      </div>

      <button
        onClick={handlePurchase}
        className="px-6 py-3 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition duration-300"
      >
        Comprar
      </button>
    </div></div>
    </div> 
  );
};

export default TicketBoxOffice;
