import React, { useState } from 'react';

const PaymentForm = () => {
  const [tab, setTab] = useState('creditCard'); // Tab selecionada
  const [value, setValue] = useState(50); // Valor inicial
  const [errors, setErrors] = useState({});
  const [pixPaid, setPixPaid] = useState(false); // Estado para indicar pagamento com PIX
  const [pixKey, setPixKey] = useState('pix-chave-exemplo@dominio.com'); // Chave PIX para exibir após pagamento

  const [creditCardInfo, setCreditCardInfo] = useState({
    cardNumber: '',
    expirationDate: '',
    securityCode: '',
    cardHolderName: '',
    cardHolderCpf: '',
  });
  const [pixInfo, setPixInfo] = useState({
    fullName: '',
    email: '',
  });

  const incrementValue = () => setValue(value + 10);
  const decrementValue = () => setValue(value > 10 ? value - 10 : value);

  const validateCreditCard = () => {
    const errors = {};
    const { cardNumber, expirationDate, securityCode, cardHolderName, cardHolderCpf } = creditCardInfo;

    if (!/^\d{16}$/.test(cardNumber)) errors.cardNumber = 'Número do cartão inválido.';
    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(expirationDate)) errors.expirationDate = 'Data de expiração inválida.';
    if (!/^\d{3}$/.test(securityCode)) errors.securityCode = 'Código de segurança inválido.';
    if (!cardHolderName.trim()) errors.cardHolderName = 'Nome do titular é obrigatório.';
    if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cardHolderCpf)) errors.cardHolderCpf = 'CPF inválido.';

    return errors;
  };

  const validatePix = () => {
    const errors = {};
    const { fullName, email } = pixInfo;

    if (!fullName.trim()) errors.fullName = 'Nome completo é obrigatório.';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) errors.email = 'E-mail inválido.';

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (tab === 'creditCard') {
      const creditErrors = validateCreditCard();
      if (Object.keys(creditErrors).length > 0) {
        setErrors(creditErrors);
      } else {
        setErrors({});
        alert('Pagamento com cartão realizado com sucesso!');
      }
    } else if (tab === 'pix') {
      const pixErrors = validatePix();
      if (Object.keys(pixErrors).length > 0) {
        setErrors(pixErrors);
      } else {
        setErrors({});
        setPixPaid(true); // Marcar PIX como pago
      }
    }
  };

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    if (section === 'creditCard') {
      setCreditCardInfo({ ...creditCardInfo, [name]: value });
    } else if (section === 'pix') {
      setPixInfo({ ...pixInfo, [name]: value });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixKey);
    alert('Chave PIX copiada para a área de transferência!');
  };

  return (
    <div>
      {/* Seleção do valor */}
      <div className="mb-6 flex items-center gap-4">
        <button
          onClick={decrementValue}
          className="px-4 py-2 bg-gray-300 rounded-lg text-lg font-bold"
        >
          -
        </button>
        <span className="text-2xl font-bold">R$ {value}</span>
        <button
          onClick={incrementValue}
          className="px-4 py-2 bg-gray-300 rounded-lg text-lg font-bold"
        >
          +
        </button>
      </div>

      {/* Tabs */}
      <div className="mb-6 flex justify-center gap-4">
        <button
          onClick={() => setTab('creditCard')}
          className={`px-6 py-2 rounded-lg font-bold ${
            tab === 'creditCard' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
          }`}
        >
          Cartão de Crédito
        </button>
        <button
          onClick={() => setTab('pix')}
          className={`px-6 py-2 rounded-lg font-bold ${
            tab === 'pix' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
          }`}
        >
          PIX
        </button>
      </div>

      {/* Formulário */}
      <form onSubmit={handleSubmit}>
        {/* Cartão de Crédito */}
        {tab === 'creditCard' && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Número do Cartão</label>
              <input
                type="text"
                name="cardNumber"
                value={creditCardInfo.cardNumber}
                onChange={(e) => handleInputChange(e, 'creditCard')}
                className="w-full px-3 py-2 border rounded-lg"
                placeholder="1234 5678 9012 3456"
              />
              {errors.cardNumber && <p className="text-red-500 text-sm mt-1">{errors.cardNumber}</p>}
            </div>
            <div className="mb-4 flex gap-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2">Data de Expiração</label>
                <input
                  type="text"
                  name="expirationDate"
                  value={creditCardInfo.expirationDate}
                  onChange={(e) => handleInputChange(e, 'creditCard')}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="MM/AA"
                />
                {errors.expirationDate && (
                  <p className="text-red-500 text-sm mt-1">{errors.expirationDate}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">Código de Segurança</label>
                <input
                  type="text"
                  name="securityCode"
                  value={creditCardInfo.securityCode}
                  onChange={(e) => handleInputChange(e, 'creditCard')}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="123"
                />
                {errors.securityCode && (
                  <p className="text-red-500 text-sm mt-1">{errors.securityCode}</p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Nome do Titular</label>
              <input
                type="text"
                name="cardHolderName"
                value={creditCardInfo.cardHolderName}
                onChange={(e) => handleInputChange(e, 'creditCard')}
                className="w-full px-3 py-2 border rounded-lg"
                placeholder="Nome completo"
              />
              {errors.cardHolderName && (
                <p className="text-red-500 text-sm mt-1">{errors.cardHolderName}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">CPF do Titular</label>
              <input
                type="text"
                name="cardHolderCpf"
                value={creditCardInfo.cardHolderCpf}
                onChange={(e) => handleInputChange(e, 'creditCard')}
                className="w-full px-3 py-2 border rounded-lg"
                placeholder="000.000.000-00"
              />
              {errors.cardHolderCpf && (
                <p className="text-red-500 text-sm mt-1">{errors.cardHolderCpf}</p>
              )}
            </div>
            <button
              type="submit"
              className="px-6 py-2 bg-green-500 text-white rounded-lg font-bold hover:bg-green-600 w-full"
            >
              Pagar com Cartão
            </button>
          </>
        )}

        {/* PIX */}
        {tab === 'pix' && !pixPaid && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Nome Completo</label>
              <input
                type="text"
                name="fullName"
                value={pixInfo.fullName}
                onChange={(e) => handleInputChange(e, 'pix')}
                className="w-full px-3 py-2 border rounded-lg"
                placeholder="Nome completo"
              />
              {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">E-mail</label>
              <input
                type="email"
                name="email"
                value={pixInfo.email}
                onChange={(e) => handleInputChange(e, 'pix')}
                className="w-full px-3 py-2 border rounded-lg"
                placeholder="exemplo@dominio.com"
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            <button
              type="submit"
              className="px-6 py-2 bg-green-500 text-white rounded-lg font-bold hover:bg-green-600 w-full"
            >
              Pagar com PIX
            </button>
          </>
        )}

        {/* PIX Pago */}
        {tab === 'pix' && pixPaid && (
          <>
            <div className="mb-4 text-center">
              <p className="text-lg font-bold text-gray-700">Use o QR Code ou a chave abaixo para concluir o pagamento:</p>
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${pixKey}&size=200x200`}
                alt="QR Code PIX"
                className="mx-auto my-4"
              />
              <p className="text-gray-700 font-semibold">Chave PIX:</p>
              <p className="text-blue-600 font-bold mb-4">{pixKey}</p>
              <button
                onClick={copyToClipboard}
                type="button"
                className="px-6 py-2 bg-blue-500 text-white rounded-lg font-bold hover:bg-blue-600"
              >
                Copiar Chave PIX
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default PaymentForm;
