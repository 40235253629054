import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"

import { data_user } from '../DataAPI/datauser'

import { global } from '../DataAPI/global'

import { MeuContexto } from "../App"

import CompActive from '../Componentes/CompActive'

import { validarME } from '../DataAPI/apiMe'

import { data_fatlives } from '../DataAPI/datafatlives'

import QRCode from "react-qr-code";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TicketPurchaseForm = ({ artista, publico, onClose }) => {


var vrpublico =[]


  let history = useHistory();

  const [paymentMethod, setPaymentMethod] = useState('pix'); // Método de pagamento escolhido
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    cpf: '',
    password: '',
    confirmPassword: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    installments: '1',
  });


  const { logado, setlogado, setusername, novousuario, setnovousuario, user, setuser } = useContext(MeuContexto)

  const [isloading, setisloading] = useState(false)

  const [pixPaid, setPixPaid] = useState(false); // Estado para indicar pagamento com PIX
  const [pixKey, setPixKey] = useState('pix-chave-exemplo@dominio.com'); // Chave PIX para exibir após pagamento

  const [getinvoiceid, setinvoiceid] = useState('')


  useEffect(
    () => {

      // if (logado) { history.push("/") }

    }, [logado])



     // Função para validar CPF/CNPJ com dígito verificador
  const isValidCpfCnpj = (value) => {
    const cleanValue = value.replace(/\D/g, '');

    // Validação de CPF
    if (cleanValue.length === 11) {
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanValue.charAt(i)) * (10 - i);
      }
      let firstDigit = (sum * 10) % 11;
      if (firstDigit === 10 || firstDigit === 11) firstDigit = 0;

      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cleanValue.charAt(i)) * (11 - i);
      }
      let secondDigit = (sum * 10) % 11;
      if (secondDigit === 10 || secondDigit === 11) secondDigit = 0;

      return firstDigit === parseInt(cleanValue.charAt(9)) &&
        secondDigit === parseInt(cleanValue.charAt(10));
    }

    // Validação de CNPJ
    if (cleanValue.length === 14) {
      let sum = 0;
      const weight1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const weight2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

      for (let i = 0; i < 12; i++) {
        sum += parseInt(cleanValue.charAt(i)) * weight1[i];
      }
      let firstDigit = (sum % 11) < 2 ? 0 : 11 - (sum % 11);

      sum = 0;
      for (let i = 0; i < 13; i++) {
        sum += parseInt(cleanValue.charAt(i)) * weight2[i];
      }
      let secondDigit = (sum % 11) < 2 ? 0 : 11 - (sum % 11);

      return firstDigit === parseInt(cleanValue.charAt(12)) &&
        secondDigit === parseInt(cleanValue.charAt(13));
    }

    return false;
  };

  // Função para aplicar máscara no CPF/CNPJ
  const maskCpfCnpj = (value) => {
    const cleanValue = value.replace(/\D/g, '');
    if (cleanValue.length <= 11) {
      return cleanValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      return cleanValue
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }
  };

  // Função para aplicar máscara no vencimento do cartão (MM/AA)
  const maskExpirationDate = (value) => {
    return value.replace(/\D/g, '').replace(/(\d{2})(\d{1,2})$/, '$1/$2');
  };


  // Validações
  const validateForm = () => {
    const errors = {};

    // Campos obrigatórios
    if (!formData.fullName.trim()) {
      errors.fullName = 'Nome completo é obrigatório.';
    }
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'E-mail inválido.';
    }
    if (!formData.cpf.trim() || !isValidCpfCnpj(formData.cpf)) {
      errors.cpf = 'CPF ou CNPJ inválido.';
    }
    if (!formData.password.trim()) {
      errors.password = 'Senha é obrigatória.';
    }
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'As senhas não coincidem.';
    }

    // Validações específicas para cartão de crédito
    if (paymentMethod === 'creditCard') {
      if (!/^\d{16}$/.test(formData.cardNumber)) {
        errors.cardNumber = 'Número do cartão inválido. Deve ter 16 dígitos.';
      }
      if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expirationDate)) {
        errors.expirationDate = 'Data de vencimento inválida. Formato: MM/AA.';
      }
      if (!/^\d{3}$/.test(formData.cvv)) {
        errors.cvv = 'CVV inválido. Deve ter 3 dígitos.';
      }
    }

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
        // Máscaras automáticas
        if (name === 'cpf') {
          setFormData({ ...formData, [name]: maskCpfCnpj(value) });
        } else if (name === 'expirationDate') {
          setFormData({ ...formData, [name]: maskExpirationDate(value) });
        } else {
          setFormData({ ...formData, [name]: value });
        }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      console.log('Formulário enviado:', formData, 'Método de pagamento:', paymentMethod);

      if (paymentMethod === 'pix') {
        await cadastrar(e)
      //  toast.success('Compra concluída com PIX!');
      } else if (paymentMethod === 'creditCard') {

        await cadastrar(e)
      //  toast.success('Compra concluída com Cartão de Crédito!');
      } else {
        toast.info('Por favor, escolha um método de pagamento.');
      }
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixKey);
    toast.info('Chave PIX copiada para a área de transferência!');
  };


  const verificarPagamento = async () => {



    const me = await validarME.me()
    if (me) {

      publico = me

      setuser(me)

      const respostapgto = await data_fatlives.extratoPago(artista._id, publico._id)

      if (respostapgto.pago) {


        toast.success('Pagamento Realizado com sucesso!');
        setlogado(true)
     
        onClose()
        

      } else { toast.info('Ainda não Localizamos seu pagamento') }

    }




  };



  async function cadastrar(e) {

    e.preventDefault();

    const resposta = await data_user.novo({ username: formData.fullName, email: formData.email, password: formData.password, artist: false, cpf: formData.cpf });
    console.log(resposta, '----------resposta')
    if (resposta) {
      const resposta2 = await data_user.conectar({ email: formData.email, password: formData.password });
      console.log(resposta2, '----------resposta2')
      if (resposta2) {

        global.token = resposta2.token

        //setlogado(true)
        //history.push("/")
      //  toast.info(global.token)


        const me = await validarME.me()
        if (me) {

          vrpublico = me
          setuser(me)
          setusername(me.username)
          setnovousuario(true)
          if (paymentMethod === 'creditCard') {
            await pagarCartao(e)
          } else {
            await pagarPIX(e)
          }
       
        }



      } else {
        toast.error('Tivemos um problema na Entrada da Plataforma, favor Entrar com email e senha');
        history.push("/")
      }

    } else {
      toast.error('Tivemos um problema na Entrada da Plataforma, favor Entrar com email e senha');
      history.push("/")
    }

  }

  async function pagarPIX(e) {

    e.preventDefault();


    setisloading(true)


    const datapagamentopix = {



      publicoid: vrpublico._id,
      artistaid: artista._id,
      valor: artista.preco,
      payer_name: vrpublico.username,
      email: vrpublico.email,
      liveemail: artista.email,
      username: vrpublico.username,
      livename: artista.username,
      livepaga: artista.paga,



    }

    //console.log(datapagamentopix, '--------datapagamentopix')


    const resposta = await data_fatlives.pagariugupix(datapagamentopix)

    //console.log(resposta, '------resposta')
    if (resposta) {



      const { rid, rmsg, qrcodepix, invoiceidpix } = resposta

      setisloading(false)
      if (qrcodepix != '' && rmsg == "QRCODE PIX GERADO") {


        setPixPaid(true)
        setPixKey(qrcodepix)
        setinvoiceid(invoiceidpix)

        // setlogado(true)
        // console.log(qrcodepix, 'qrcodepixqrcodepixqrcodepix')

      } else {

        toast.error(
          rmsg
        )

      }

    } else {



      toast.error(
        'Tivemos Problema no PIX'
      )


      setisloading(false)
      setlogado(true)
      onClose()


    }

    setisloading(false)
  }



  async function pagarCartao(e) {

    e.preventDefault();

    setisloading(true)

    var vrfirstName = formData.fullName.split(' ')[0].toUpperCase();
    var vrlastName = formData.fullName.split(' ').slice(1).join(' ').toUpperCase();

    var vrmes = formData.expirationDate.split('/')[0];
    var vrano = formData.expirationDate.split('/').slice(1).join('/');

    const datapagamento = {
      card_number: formData.cardNumber,
      card_cvv: formData.cvv,
      vrfirstName,
      vrlastName,
      vrmes,
      vrano,
      price_cents: artista.preco,
      cpf_cnpj: formData.cpf,
      card_holder_name: formData.fullName,
      email: vrpublico.email,
      liveemail: artista.email,
      username: vrpublico.username,
      livename: artista.username,
      livepaga: artista.paga,
      userid: vrpublico._id,
      liveid: artista._id,
      parcelas: formData.installments,
    }



    // console.log(datapagamento, '------ datapagamento resposta')

    const resposta = await data_fatlives.pagariugu(datapagamento)

    // console.log(resposta, '------datapagamento resposta')
    if (resposta) {


      const { rid, rmsg } = resposta
      if (rid !== 0) {


        toast.success(

          rmsg
        );

        setlogado(true) 

        onClose()

      } else {

    setisloading(false)

        toast.error(

          rmsg
        );


        setlogado(true) 

        onClose()

      }




    } else {
      toast.error(

        "ERRO NO CARTÃO DE CRÉDITO!"
      );

      setisloading(false)

      setlogado(true)
      onClose()
    }


    setisloading(false)

  }


  return (

    <>
      {(isloading) ? (<><CompActive /></>) : (<>

        <div>

          <h5 className='text-black'>{artista.username} - Ingresso :<span className="text-2xl font-bold"> R$ {artista.preco}</span></h5>

          {/* PIX Pago */}
          {paymentMethod === 'pix' && pixPaid ? (
            <>
              <div className="mb-4 text-center flex flex-col items-center">
                {/* Texto explicativo */}
                <p className="text-lg font-bold text-gray-700 max-w-lg break-words">
                  Use o QR Code ou a chave abaixo para concluir o pagamento:
                </p>

                {/* QR Code */}
                <div className="my-4">
                  <QRCode value={pixKey} level="L" size={240} />
                </div>

                {/* Texto e chave PIX */}
                <p className="text-blue-600 font-bold mb-2 max-w-lg break-all text-center">{pixKey}</p>

                <div className="mb-4 flex gap-4">
                  {/* Botão para copiar a chave */}
                  <button
                    onClick={copyToClipboard}
                    type="button"
                    className="px-6 py-2 bg-blue-500 text-white rounded-lg font-bold hover:bg-blue-600"
                  >
                    Copiar Chave PIX
                  </button>

                  {/* erificar Pagamento */}
                  <button
                    onClick={verificarPagamento}
                    type="button"
                    className="ml-1 px-6 py-2 bg-yellow-500 text-white rounded-lg font-bold hover:bg-blue-600"
                  >
                    Verificar Pagamento
                  </button>

                </div>
              </div>
            </>
          ) : <>


            <form
              onSubmit={handleSubmit}
              className="bg-white p-1 rounded-lg w-full max-w-lg text-black"
            >
              {/* Campos de Cadastro */}
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Nome Completo</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Digite seu nome completo"
                />
                {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">E-mail</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Digite seu e-mail"
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">CPF</label>
                <input
                  type="text"
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Digite seu CPF"
                />
                {errors.cpf && <p className="text-red-500 text-sm">{errors.cpf}</p>}
              </div>
              <div className="mb-4 flex gap-4">
                <div >
                  <label className="block text-gray-700 font-bold mb-2">Senha</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="Digite sua senha"
                  />
                  {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                </div>
                <div >
                  <label className="block text-gray-700 font-bold mb-2">Confirmar Senha</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="Confirme sua senha"
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
                  )}
                </div>

              </div>
              {/* Botões de Método de Pagamento */}
              <div className="mb-6">
                <div className="flex justify-around">
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('pix')}
                    className={`px-4 py-2 border rounded-lg font-bold ${paymentMethod === 'pix' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                      }`}
                  >
                    PIX
                  </button>
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('creditCard')}
                    className={`px-4 py-2 border rounded-lg font-bold ${paymentMethod === 'creditCard'
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-700'
                      }`}
                  >
                    Cartão de Crédito
                  </button>
                </div>
              </div>
              {/* Campos do Cartão de Crédito */}
              {paymentMethod === 'creditCard' && (
                <>
                  <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">Número do Cartão</label>
                    <input
                      type="text"
                      name="cardNumber"
                      value={formData.cardNumber}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border rounded-lg"
                      placeholder="1234 5678 9012 3456"
                    />
                    {errors.cardNumber && <p className="text-red-500 text-sm">{errors.cardNumber}</p>}
                  </div>
                  <div className="mb-4 flex gap-4">
                    <div>
                      <label className="block text-gray-700 font-bold mb-2">Vencimento</label>
                      <input
                        type="text"
                        name="expirationDate"
                        value={formData.expirationDate}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border rounded-lg"
                        placeholder="MM/AA"
                      />
                      {errors.expirationDate && (
                        <p className="text-red-500 text-sm">{errors.expirationDate}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-gray-700 font-bold mb-2">CVV</label>
                      <input
                        type="text"
                        name="cvv"
                        value={formData.cvv}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border rounded-lg"
                        placeholder="123"
                      />
                      {errors.cvv && <p className="text-red-500 text-sm">{errors.cvv}</p>}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">Parcelas</label>
                    <select
                      name="installments"
                      value={formData.installments}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border rounded-lg"
                    >
                      <option value="1">1x</option>
                      <option value="2">2x</option>
                      <option value="3">3x</option>
                      <option value="4">4x</option>
                      <option value="5">5x</option>
                      <option value="6">6x</option>
                    </select>
                  </div>
                </>
              )}

              {/* Botão de Enviar */}
              <button
                type="submit"
                className="w-full px-4 py-2 bg-green-500 text-white rounded-lg font-bold hover:bg-green-600"
              >
                Finalizar Compra
              </button>
            </form>


          </>}


          {/* Container para o toast */}
          <ToastContainer />

        </div>
      </>)
      }
    </>
  );
};

export default TicketPurchaseForm;
