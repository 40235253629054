import React, { useEffect, useState, useContext } from 'react';


import { MeuContexto } from "../App"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import CompActive from '../Componentes/CompActive'

import QRCode from "react-qr-code";

import { useHistory } from "react-router-dom";

import { data_fatlives } from '../DataAPI/datafatlives'

import { validarME } from '../DataAPI/apiMe'

const PaymentForm = ({ artista, publico, onClose }) => {


    let history = useHistory();

    const [isloading, setisloading] = useState(false)

  const { logado, setlogado, setusername, novousuario, setnovousuario, user, setuser } = useContext(MeuContexto)

  const [tab, setTab] = useState('pix'); // Tab selecionada
  const [value, setValue] = useState(50); // Valor inicial
  const [errors, setErrors] = useState({});
  const [pixPaid, setPixPaid] = useState(false); // Estado para indicar pagamento com PIX
  const [pixKey, setPixKey] = useState('pix-chave-exemplo@dominio.com'); // Chave PIX para exibir após pagamento

  const [installments, setInstallments] = useState('1'); // Número de parcelas selecionado

    const [getinvoiceid, setinvoiceid] = useState('')

  const [creditCardInfo, setCreditCardInfo] = useState({
    cardNumber: '',
    expirationDate: '',
    securityCode: '',
    cardHolderName: '',
    cardHolderCpf: '',
  });
  const [pixInfo, setPixInfo] = useState({
    fullName: '',
    email: '',
  });

  const incrementValue = () => setValue(value + 10);
  const decrementValue = () => setValue(value > 10 ? value - 10 : value);

    useEffect(
        () => {

            if (artista.paga) {

        setValue(artista.preco)
            }

      setPixInfo({
        fullName: publico.username,
        email: publico.email
      })

            LocalizarPIX()

        },
        []
    )


    useEffect(
        () => {

            if (!logado) { history.push("/SignIn") }
        }, [logado])

    async function LocalizarPIX() {


    setisloading(true)

        // console.log(vr_liveid, vr_userid, '--------vr_userid,vr_liveid')


        const resposta = await data_fatlives.localizarPIX(artista._id, publico._id)

        //  console.log(resposta, '------resposta')
        if (resposta) {
            if (resposta[0]) {
                setinvoiceid(resposta[0].invoiceid)
        setPixKey(resposta[0].qrcode_text)
        setValue(resposta[0].valor / 100)
        setPixPaid(true)
        setTab('pix')
      }
    }


            setisloading(false)

        }


  const validateCreditCard = () => {
    const errors = {};
    const { cardNumber, expirationDate, securityCode, cardHolderName, cardHolderCpf } = creditCardInfo;

    if (!/^\d{16}$/.test(cardNumber)) errors.cardNumber = 'Número do cartão inválido.';
    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(expirationDate)) errors.expirationDate = 'Data de expiração inválida.';
    if (!/^\d{3}$/.test(securityCode)) errors.securityCode = 'Código de segurança inválido.';
    if (!cardHolderName.trim()) errors.cardHolderName = 'Nome do titular é obrigatório.';
    if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cardHolderCpf)) errors.cardHolderCpf = 'CPF inválido.';

    return errors;
  };

  const validatePix = () => {
    const errors = {};
    const { fullName, email } = pixInfo;

    if (!fullName.trim()) errors.fullName = 'Nome completo é obrigatório.';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) errors.email = 'E-mail inválido.';

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tab === 'creditCard') {
      const creditErrors = validateCreditCard();
      if (Object.keys(creditErrors).length > 0) {
        setErrors(creditErrors);
      } else {
        setErrors({});

        await pagar()

     //   toast.success('Pagamento com cartão realizado com sucesso!');
      }
    } else if (tab === 'pix') {
      const pixErrors = validatePix();
      if (Object.keys(pixErrors).length > 0) {
        setErrors(pixErrors);
      } else {
        setErrors({});


        await GerarPIXsim()



        setPixPaid(true); // Marcar PIX como pago
      }
    }
  };



  const verificarPagamento = async () => {



    const me = await validarME.me()
    if (me) {



      setuser(me)

      const respostapgto = await data_fatlives.extratoPago(artista._id, publico._id)

      if (respostapgto.pago) {


        toast.success('Pagamento Realizado com sucesso!');
        setlogado(true)
        window.location.reload();

      } else { toast.info('Ainda não Localizamos seu pagamento') }

    }




  };


    async function GerarPIXsim() {



        setisloading(true)


        const datapagamentopix = {



            publicoid: publico._id,
            artistaid: artista._id,
      valor: value,
      payer_name: pixInfo.fullName,
      email: pixInfo.email,
            liveemail: artista.email,
            username: publico.username,
            livename: artista.username,
            livepaga: artista.paga,



        }

        //console.log(datapagamentopix, '--------datapagamentopix')


        const resposta = await data_fatlives.pagariugupix(datapagamentopix)

        //console.log(resposta, '------resposta')
        if (resposta) {



            const { rid, rmsg, qrcodepix, invoiceidpix } = resposta

            setisloading(false)
            if (qrcodepix != '' && rmsg == "QRCODE PIX GERADO") {


        setPixPaid(true)
        setPixKey(qrcodepix)
                setinvoiceid(invoiceidpix)
        setTab('pix')
                // console.log(qrcodepix, 'qrcodepixqrcodepixqrcodepix')

            } else {

                toast.error(
                    rmsg
                )

            }

        } else {



            toast.error(
                'Tivemos Problema no PIX'
            )


        setisloading(false)
      onClose()


    }

            setisloading(false)

  };


    async function pagar() {


        setisloading(true)

    var vrfirstName = creditCardInfo.cardHolderName.split(' ')[0].toUpperCase();
    var vrlastName = creditCardInfo.cardHolderName.split(' ').slice(1).join(' ').toUpperCase();

    var vrmes = creditCardInfo.expirationDate.split('/')[0];
    var vrano = creditCardInfo.expirationDate.split('/').slice(1).join('/');

        const datapagamento = {
      card_number: creditCardInfo.cardNumber,
      card_cvv: creditCardInfo.securityCode,
            vrfirstName,
            vrlastName,
            vrmes,
            vrano,
      price_cents: value,
      cpf_cnpj: creditCardInfo.cardHolderCpf,
      card_holder_name: creditCardInfo.cardHolderName,
            email: publico.email,
            liveemail: artista.email,
            username: publico.username,
            livename: artista.username,
            livepaga: artista.paga,
            userid: publico._id,
            liveid: artista._id,
      parcelas: installments,
        }



        // console.log(datapagamento, '------ datapagamento resposta')

        const resposta = await data_fatlives.pagariugu(datapagamento)

        // console.log(resposta, '------datapagamento resposta')
        if (resposta) {


            const { rid, rmsg } = resposta
            if (rid !== 0) {


                toast.success(

                    rmsg
                );

        onClose()

            } else {
                toast.error(

                    rmsg
                );
            }




        } else {
            toast.error(

                "ERRO NO CARTÃO DE CRÉDITO!"
            );
        }


        setisloading(false)

  }

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    if (section === 'creditCard') {
      setCreditCardInfo({ ...creditCardInfo, [name]: value });
    } else if (section === 'pix') {
      setPixInfo({ ...pixInfo, [name]: value });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixKey);
    toast.success('Chave PIX copiada para a área de transferência!');
  };

    return (

    <>
                {(isloading) ? (<><CompActive /></>) : (<>

        <div>
          {/* Seleção do valor */}


          {(artista.paga) ? (<>
            <div className="mb-6 flex-row justify-center items-center gap-4 text-black">



              <h5 >{artista.username} - Ingresso :<span className="text-2xl font-bold"> R$ {artista.preco}</span></h5>

                                    </div>
                                </>) : (<>
            <div className="mb-6 flex justify-center items-center gap-4  text-black">
              <button
                onClick={decrementValue}
                className="px-4 py-2 bg-gray-300 rounded-lg text-lg font-bold"
              >
                -
              </button>

              <h5>O valor <span className="text-2xl font-bold">R$ {value}</span> da contribuição voluntária será direcionado ao artista <span className="tlbbranco">{artista.username}, Ajuste o valor com os botões - e + nas laterais </span></h5>


              <button
                onClick={incrementValue}
                className="px-4 py-2 bg-gray-300 rounded-lg text-lg font-bold"
              >
                +
              </button>

                            </div>

          </>)}







          {/* Tabs */}
          <div className="mb-6 flex justify-center gap-4 text-black">
            <button
              onClick={() => setTab('pix')}
              className={`px-6 py-2 rounded-lg font-bold ${tab === 'pix' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
                }`}
            >
              PIX
            </button>
            <button
              onClick={() => setTab('creditCard')}
              className={`px-6 py-2 rounded-lg font-bold ${tab === 'creditCard' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
                }`}
            >
              Cartão de Crédito
            </button>

                                </div>

          {/* Formulário */}
          <form className="text-black"onSubmit={handleSubmit}>
            {/* Cartão de Crédito */}
            {tab === 'creditCard' && (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Número do Cartão</label>
                  <input
                    type="text"
                    name="cardNumber"
                    value={creditCardInfo.cardNumber}
                    onChange={(e) => handleInputChange(e, 'creditCard')}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="1234 5678 9012 3456"
                  />
                  {errors.cardNumber && <p className="text-red-500 text-sm mt-1">{errors.cardNumber}</p>}
                                    </div>
                <div className="mb-4 flex gap-4">
                                        <div>
                    <label className="block text-gray-700 font-bold mb-2">Data de Expiração</label>
                    <input
                      type="text"
                      name="expirationDate"
                      value={creditCardInfo.expirationDate}
                      onChange={(e) => handleInputChange(e, 'creditCard')}
                      className="w-full px-3 py-2 border rounded-lg"
                      placeholder="MM/AA"
                    />
                    {errors.expirationDate && (
                      <p className="text-red-500 text-sm mt-1">{errors.expirationDate}</p>
                    )}
                                        </div>
                                        <div>
                    <label className="block text-gray-700 font-bold mb-2">Código de Segurança</label>
                    <input
                      type="text"
                      name="securityCode"
                      value={creditCardInfo.securityCode}
                      onChange={(e) => handleInputChange(e, 'creditCard')}
                      className="w-full px-3 py-2 border rounded-lg"
                      placeholder="123"
                    />
                    {errors.securityCode && (
                      <p className="text-red-500 text-sm mt-1">{errors.securityCode}</p>
                    )}
                                        </div>
                  <div>
                    <label className="block text-gray-700 font-bold mb-2">Parcelas</label>
                    <select
                      value={installments}
                      onChange={(e) => setInstallments(e.target.value)}
                      className="w-full px-3 py-2 border rounded-lg"
                    >
                      <option value="1">1x</option>
                      <option value="2">2x</option>
                      <option value="3">3x</option>
                      <option value="4">4x</option>
                      <option value="5">5x</option>
                      <option value="6">6x</option>
                    </select>
                                    </div>
                            </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Nome do Titular</label>
                                    <input
                                        type="text"
                    name="cardHolderName"
                    value={creditCardInfo.cardHolderName}
                    onChange={(e) => handleInputChange(e, 'creditCard')}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="Nome completo"
                  />
                  {errors.cardHolderName && (
                    <p className="text-red-500 text-sm mt-1">{errors.cardHolderName}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">CPF do Titular</label>
                                    <input
                                        type="text"
                    name="cardHolderCpf"
                    value={creditCardInfo.cardHolderCpf}
                    onChange={(e) => handleInputChange(e, 'creditCard')}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="000.000.000-00"
                  />
                  {errors.cardHolderCpf && (
                    <p className="text-red-500 text-sm mt-1">{errors.cardHolderCpf}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="px-6 py-2 bg-green-500 text-white rounded-lg font-bold hover:bg-green-600 w-full"
                >
                  Pagar com Cartão
                </button>
              </>
            )}

            {/* PIX */}
            {tab === 'pix' && !pixPaid && (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Nome Completo</label>
                                    <input
                                        type="text"
                    name="fullName"
                    value={pixInfo.fullName}
                    onChange={(e) => handleInputChange(e, 'pix')}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="Nome completo"
                  />
                  {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">E-mail</label>
                                    <input
                    type="email"
                    name="email"
                    value={pixInfo.email}
                    onChange={(e) => handleInputChange(e, 'pix')}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="exemplo@dominio.com"
                  />
                  {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                                </div>
                <button
                  type="submit"
                  className="px-6 py-2 bg-green-500 text-white rounded-lg font-bold hover:bg-green-600 w-full"
                >
                  Pagar com PIX
                </button>

              </>
            )}

            {/* PIX Pago */}
            {tab === 'pix' && pixPaid && (
              <>
                <div className="mb-4 text-center flex flex-col items-center">
                  {/* Texto explicativo */}
                  <p className="text-lg font-bold text-gray-700 max-w-lg break-words">
                    Use o QR Code ou a chave abaixo para concluir o pagamento:
                  </p>

                  {/* QR Code */}
                  <div className="my-4">
                    <QRCode value={pixKey} level="L" size={240} />
                                </div>

                  {/* Texto e chave PIX */}
                  <p className="text-blue-600 font-bold mb-2 max-w-lg break-all text-center">{pixKey}</p>

                  <div className="mb-4 flex gap-4">
                    {/* Botão para copiar a chave */}
                    <button
                      onClick={copyToClipboard}
                      type="button"
                      className="px-6 py-2 bg-blue-500 text-white rounded-lg font-bold hover:bg-blue-600"
                    >
                      Copiar Chave PIX
                    </button>

                    {/* erificar Pagamento */}
                    <button
                      onClick={verificarPagamento}
                      type="button"
                      className="mt-1 px-6 py-2 bg-yellow-500 text-white rounded-lg font-bold hover:bg-blue-600"
                    >
                      Verificar Pagamento
                    </button>
                  </div>
                </div>
              </>
            )}
                            </form>

          {/* Container para o toast */}
          <ToastContainer />

                    </div>

                </>)
                }
    </>
  );
};

export default PaymentForm;
