import React, { useState, useContext } from 'react'
import { useHistory, Link } from "react-router-dom"

import './PgSignIn.css';

import { MeuContexto } from "../App"
import { global } from '../DataAPI/global'
import { data_user } from '../DataAPI/datauser'

import { data_email } from '../DataAPI/dataemail';

import { validar } from '../DataAPI/apiValidar'

///import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PgSignIn() {


    let history = useHistory();

    const { setlogado, setusername ,setuser} = useContext(MeuContexto)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [vemail, setvemail] = useState(true)
    const [vpassword, setvpassword] = useState(true)

    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    function _validar() {


        var retorno = true

        let formErrors = {};

        // Verificar se o e-mail está preenchido e é válido
        if (!email) {
            formErrors.email = 'Por favor, insira seu e-mail.';
            retorno = false
        } else if (!validateEmail(email)) {
            formErrors.email = 'Por favor, insira um e-mail válido.';
            retorno = false
        }

        // Verificar se a senha está preenchida
        if (!password) {
            formErrors.password = 'Por favor, insira sua senha.';
            retorno = false
        }

        // Se não houver erros, continue com o login
        if (Object.keys(formErrors).length === 0) {
            console.log('Login efetuado com sucesso!');
            // Aqui você pode adicionar a lógica de autenticação

            var retorno = true
        } else {
            setErrors(formErrors);
            retorno = false
        }

        return retorno

    }

    async function _Logar(e) {
        e.preventDefault();
        if (_validar()) {

            try {
                const resposta2 = await data_user.conectar({ email, password });
                ///    console.log(resposta2)
                if (resposta2) {
                    global.token = resposta2.token
                    try {
                        localStorage.setItem('@smizetv:token', global.token);

                    } catch (error) {

                    }

                    if (_validarme()) {

                        setlogado(true)
                        history.goBack()
                    } else {
                        setlogado(false)
                        history.push("/")
                    }

                }
            } catch (error) {
                toast.error(

                    'Favor verificar suas informações!'
                );
            }







        }
        else {
            toast.warn(

                'Favor verificar suas informações!'
            );

        }

    }
    async function _validarme() {
        const respostame = await data_user.me();
        if (respostame) {

            setuser(respostame)
            setusername(respostame.username)

            ///  console.log(respostame)
            if (respostame.banido) { return false } else { return true }
        }
        return false

    }



    function _validarRecuperarSenha() {


        if (!validar._email(email).valido) return false
        if (!validar._requerido(email).valido) return false


        return true
    }


    async function _recuperarsenha() {


        if (_validarRecuperarSenha()) {


            const resp = await data_user.novasenha(email)

            if (resp) {

                data_email.rsenha(email, resp.senha)

            }
            toast.success(

                'E-mail enviado com a nova senha!'
            );

        }
        else {
            toast.warn(

                'Favor verificar suas informações!'
            );

        }

    }

    /*
        async function responseFacebook(response) {

            if (response) {

                if (response.accessToken) {


                    const resposta = await data_user.facebook({ faceid: response.id, faceemail: response.email, facename: response.name });

                    //////console.log(resposta, 'resposta face'        )

                    if (resposta) {


                        const resposta2 = await data_user.conectar(resposta);
                        ///    console.log(resposta2)
                        if (resposta2) {
                            global.token = resposta2.token
                            try {
                                localStorage.setItem('@smizetv:token', global.token);

                            } catch (error) {

                            }

                            if (_validarme()) {

                                setlogado(true)
                                history.goBack()
                            } else {
                                setlogado(false)
                                history.push("/")
                            }

                        }

                    }



                }


            }


            //////console.log(response)




        }

        */
    function componentClicked() { }


    return (
<div className="flex justify-center mt-10 ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold text-center mb-6 text-black">Entrar</h2>
        
        <form onSubmit={_Logar}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              E-mail
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                errors.email ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
              }`}
              placeholder="Digite seu e-mail"
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Senha
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                errors.password ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
              }`}
              placeholder="Digite sua senha"
            />
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-950 text-white font-bold py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300"
          >
            Entrar
          </button>
        </form>

        {/* Container para o toast */}
        <ToastContainer />

        <div className="flex justify-between items-center mt-4">
          <a href="#"   onClick={(e) => {    e.preventDefault();    _recuperarsenha();  }} className="text-indigo-950 text-sm hover:underline">Recuperar senha</a>
          <a href="/SignUp" className="text-indigo-950 text-sm hover:underline">Criar conta</a>
        </div>
      </div>
    </div>
  );
}

export default PgSignIn;