import React, { useEffect, useRef, useState } from 'react'



import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import Shorts from './Shorts'


function ShortsLista({ shortslist }) {

    const barref = useRef();
 

    

    const btesquerda = (barra) => {

        barra.scrollLeft -= 400
    }
    const btdireita = (barra) => {

        barra.scrollLeft += 400
    }


    return (
        <div>
            <MdChevronLeft className='hidden md:block  text-white text-[32px] absolute mx-1 mt-[100px] cursor-pointer z-50' onClick={() => { btesquerda(barref.current) }} />
            <MdChevronRight className='hidden md:block text-white text-[32px] absolute mx-4 mt-[100px] cursor-pointer right-0 z-50' onClick={() => { btdireita(barref.current) }} />

            <div className='flex gap-8 overflow-x-auto scrollbar-hide scroll-smooth p-4 pl-12' ref={barref}>


                {shortslist.map((item, index) => (
                    <div key={index}>

                        <Shorts item={item} />
                    </div>



                ))}
            </div>

        </div>

    )
}

export default ShortsLista